<script setup lang="ts">
import { useForm } from 'vee-validate'
import { EContent } from 'auth/enums/dialog-contents'
import { signInSchema } from 'auth/validation-schema'
import IconLogo from '@/assets/images/logo.svg'

const emit = defineEmits<{
  (e: 'switch', value: EContent): void
}>()

const { defineComponentBinds, handleSubmit, errors, submitCount } = useForm({
  validationSchema: signInSchema,
})
const email = defineComponentBinds('email')
const password = defineComponentBinds('password')
const { isSubmitted } = useValidation(submitCount)

const authStore = useAuthStore()
const signIn = handleSubmit(async (values) => {
  await authStore.login(values.email, values.password)
  useKlaviyo('identify')
})
const proceedToSignUp = () => {
  const route = useRoute()
  emit(
    'switch',
    route.name === 'become-a-creator-page'
      ? EContent.SIGN_UP_FIRST_CREATOR
      : EContent.SIGN_UP_FIRST_BRAND,
  )
}
</script>

<template>
  <form
    class="flex flex-col items-center"
    novalidate
    @submit.prevent="signIn"
  >
    <IconLogo
      aria-label="Branded Content logo"
      class="w-[137px]"
      role="img"
    />
    <h2 class="my-8 text-4xl font-normal">Log In</h2>

    <AuthViewSignInWithSocial />

    <div class="flex w-full flex-col items-start">
      <div class="p-float-label w-full">
        <InputText
          id="email"
          v-bind="email"
          id-cy="sign-in-email-input"
          type="email"
          :class="['w-full', { 'p-invalid': errors.email && isSubmitted }]"
        />
        <label for="email">Enter your email</label>
      </div>
      <small
        v-if="errors.email && isSubmitted"
        class="p-error mt-1"
      >
        {{ errors.email }}
      </small>
      <div class="p-float-label mt-7 w-full">
        <Password
          id="password"
          v-bind="password"
          id-cy="sign-in-password-input"
          :class="['w-full', { 'p-invalid': errors.password && isSubmitted }]"
          input-class="w-full"
          :feedback="false"
          toggle-mask
        />
        <label
          for="password"
          aria-label="Enter your password"
        >
          • • • • • • • • • • •
        </label>
      </div>
      <small
        v-if="errors.password && isSubmitted"
        class="p-error mt-1"
      >
        {{ errors.password }}
      </small>
      <Button
        id-cy="sign-in-submit-btn"
        type="submit"
        class="mb-4 mt-6 w-full justify-center [&>.p-button-label]:flex-none"
        :disabled="authStore.isProcessing"
        :label="!authStore.isProcessing ? 'Continue' : 'Loading'"
        :icon="authStore.isProcessing ? 'pi pi-spin pi-spinner' : undefined"
      />
    </div>
    <p
      class="mb-4 flex w-full flex-wrap justify-center border-b border-gray-500 pb-4 font-normal text-gray-100"
    >
      Forgot your password?
      <Button
        class="p-button-link ml-1 p-0 align-top font-normal"
        label="Recover"
        @click="$emit('switch', EContent.RECOVER_PASSWORD)"
      />
    </p>
    <p
      class="m-0 flex w-full flex-wrap justify-center font-normal text-gray-100"
    >
      Don't have an account?
      <Button
        class="p-button-link ml-1 p-0 align-top font-normal"
        label="Sign Up"
        @click="proceedToSignUp"
      />
    </p>
  </form>
</template>
